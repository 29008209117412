<template>
    <div class="wrap">
        <section class="banner"></section>
        <section class="recruit-content">
            <div class="content-container">
                <div class="title">加入我们</div>
                <div class="info">
                    <div>公司所有职位均由公司负责社保、医保。如个人另有中级职称或招标师资格，加500/月，有C牌驾照优先考虑。有意者请将个人简历发至福州永诚招标代理有限公司。</div>
                    <div>
                        <span>企业邮箱：yongcheng@yongcheng.com</span>
                        <span>联系人：陈经理</span>
                        <span>联系电话：15340386581</span>
                    </div>
                    <div>公司地址：福建省福州市鼓楼区鼓东街道中山路23号省工业品交易中心大楼1101室-1</div>
                </div>
                <div class="title">招聘岗位</div>
                <!-- <table class="table">
                    <tr class="header">
                        <th>岗位名称</th>
                        <th>工作地点</th>
                        <th>学历要求</th>
                        <th>招聘人数</th>
                        <th>薪资待遇</th>
                        <th>发布时间</th>
                        <th>岗位描述</th>
                    </tr>
                    <template v-for="(item, index) in postInfo" :key="item">
                        <tr class="body">
                            <td>{{item.name}}</td>
                            <td>{{item.locale}}</td>
                            <td>{{item.certificate}}</td>
                            <td>{{item.num}}</td>
                            <td>{{item.salary}}</td>
                            <td>{{item.updated_at ? item.updated_at.split(' ')[0] : ''}}</td>
                            <td @click="open(index)" :class="{on: showList[index]}">
                                展开
                                <el-icon class="my-icon">
                                    <ArrowDownBold />
                                </el-icon>
                            </td>
                        </tr>
                        <tr class="detail">
                            <td><div class="content ql-editor" v-show="showList[index]" v-html="item.content"></div></td>
                            
                        </tr>
                    </template>
                </table> -->

                <div class="post-table">
                    <div class="th">
                        <span class="name">岗位名称</span>
                        <span class="loacale">工作地点</span>
                        <span class="certificate">学历要求</span>
                        <span class="num">招聘人数</span>
                        <span class="salary">薪资待遇</span>
                        <span class="updated_at">发布时间</span>
                        <span class="description">岗位描述</span>
                    </div>
                    <div class="body">
                        <template v-for="(item, index) in postInfo" :key="item">
                            <div class="td">
                                <span class="name">{{item.name}}</span>
                                <span class="loacale">{{item.locale}}</span>
                                <span class="certificate">{{item.certificate}}</span>
                                <span class="num">{{item.num}}</span>
                                <span class="salary">{{item.salary}}</span>
                                <span class="updated_at">{{item.updated_at ? item.updated_at.split(' ')[0] : ''}}</span>
                                <span class="description" @click="open(index)" :class="{on: showList[index]}">
                                    展开
                                    <el-icon class="my-icon">
                                        <ArrowDownBold />
                                    </el-icon>
                                </span>
                            </div>
                            <div class="content ql-editor" v-show="showList[index]" v-html="item.content"></div>
                        </template>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import {
    getCurrentInstance,
    reactive,
    toRefs
} from 'vue'
import { ArrowDownBold } from '@element-plus/icons'
export default {
    name: 'recruit',
    components: {
        ArrowDownBold
    },
    setup() {
        const { proxy } = getCurrentInstance()
        const state = reactive({
            postInfo: [],
            showList: []
        })
        const open = (index) => {
            state.showList[index] = !state.showList[index]
        }

        proxy.$api.job.getJobList().then(res => {
            state.postInfo = res.data && res.data.length ? res.data : []
        })

        

        return {
            ...toRefs(state),
            open
        }
    }
}
</script>
<style lang="scss" scoped>
    @import "index";
</style>